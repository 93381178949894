import React, { useEffect, useState } from 'react';
import logo from './assets/images/logo_pixel.jpeg';
import pepe from './assets/images/8b.gif'
import './App.css';

//components
import PlaySound from './components/PlaySound';
import ParentComponent from './components/ParentComponent';

import icp from './assets/images/icpepe.svg';
import Modal from 'react-modal';
import videoPepe from './assets/images/video.mp4';


function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (
    <div className="App">
      <Modal isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)} className="video">
        <button onClick={() => setModalIsOpen(false)}>Close</button>
        <video loop autoplay="" controls>
              <source src={videoPepe} type="video/mp4" /> 
            </video>
        <button onClick={() => setModalIsOpen(false)}>X</button>
      </Modal>
      <div className="appHeader"  data-aos="fade-right" data-aos-duration="4000">
        <a href="https://app.icpswap.com/swap?input=ryjl3-tyaaa-aaaaa-aaaba-cai&output=o2ul2-3aaaa-aaaak-afmja-cai" target="_blank">
           Buy Pepe NOW
        </a>
        <a className="howtoBuy"  onClick={() => setModalIsOpen(true)}>
          How to buy
        </a>
      </div>
      <span className="icp">
        <img src={icp} alt="icp" />
      </span>
      <PlaySound />
      <div className="App-content flex">
        <div className="leftSide w-2/12">
          <ParentComponent />
        </div>
        <div className="centerSide w-8/12">
        <div className="pepe-bg">
            <div className="animation"></div>
         
            <img src={pepe} alt="pepe" />
            <span className="border-pepe"></span>
          </div>
        </div>
        <div className="RightSide w-2/12"></div>
      </div>
    </div>
  );
}

export default App;
