import Sound from 'react-sound';
import React, { useState, useEffect } from 'react';

import shadilay from  '.././assets/music/shadilay8bit.mp3';

const PlaySound = ({ handleSongLoading, handleSongPlaying, handleSongFinishedPlaying }) => {
    const [isPlaying, setIsPlaying] = useState(false);

    const handleUserInteraction = () => {
        setIsPlaying(true);
        // Remove event listeners after user interaction
        window.removeEventListener('mousedown', handleUserInteraction);
        window.removeEventListener('keydown', handleUserInteraction);
    };

    useEffect(() => {
        // Add event listeners for user interaction
        window.addEventListener('mousedown', handleUserInteraction);
        window.addEventListener('keydown', handleUserInteraction);

        // Clean up event listeners on unmount
        return () => {
            window.removeEventListener('mousedown', handleUserInteraction);
            window.removeEventListener('keydown', handleUserInteraction);
        };
    }, []);

    return ( 
        <div>
            <button onClick={() => setIsPlaying(!isPlaying)}>
                {isPlaying ? 'Pause' : 'Play'}
            </button>

            <Sound
                url={shadilay}
                playStatus={isPlaying ? Sound.status.PLAYING : Sound.status.STOPPED}
                playFromPosition={300}
                onLoading={handleSongLoading}
                onPlaying={handleSongPlaying}
                onFinishedPlaying={handleSongFinishedPlaying}
            />
        </div>
    );
}

export default PlaySound;